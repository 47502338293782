<template>
  <div class="mt-2 mb-2">
    <div class="mt-1">
      <b-row>
        <b-col
          lg="3"
          class="titleSpace"
        >
          注目のキーワード
        </b-col>
        <b-col>
          <b-badge
            v-for="(data, index) in choice"
            :key="index"
            class="badge-inline"
            variant="light-primary"
            @click="makeToast(data)"
          >
            <a class="text-primary">
              # {{ data }}
            </a>
          </b-badge>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
  },
  data() {
    return {
      choice: [
        '初めての起業',
        '鬼滅の刃を語る',
        'プレゼン大会',
        'クラウドファンディング',
        'kid\'s スピーチコンテント',
      ],
    }
  },
  methods: {
    makeToast(val) {
      this.$bvToast.toast('検索開始の代理表示です。', {
        title: `${val} is selected`,
        variant: 'primary',
        solid: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.badge-inline {
  margin: 0 1% 0 0;
}
.titleText_ {
  font-size: 1.2rem;
  font-weight: 600;
}
.titleSpace {
  text-align: left;
}
@media screen and (min-width:896px){
  .titleSpace {
    text-align: right;
  }
}
</style>
