<template>
  <div>
    <h2>イベントから探す</h2>
    <b-card
      title=""
    >
      <h3>テキストテキストテキスト</h3>
      <div>
        テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
      </div>
      <div class="">
        <b-row class="mt-3 mb-2">
          <b-col lg="4">
            <div class="">
              <small>カテゴリ</small>
            </div>
            <b-form-input
              v-model="sarchwords"
            />
          </b-col>
          <b-col lg="4">
            <div class="">
              <small>参加形態</small>
            </div>
            <!-- <b-form-input
              v-model="learingType"
            /> -->
            <v-select
              v-model="learingType"
              :options="choice"
              @input="send"
            />
          </b-col>
          <b-col class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="search"
            >
              検索する
            </b-button>
          </b-col>
        </b-row>
      </div>
      <History class="mt-4" />
      <Popular class="mb-3" />
    </b-card>
    <TopLessonList title="注目のイベント" />
    <TopLessonList title="人気のイベント" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BFormInput,
} from 'bootstrap-vue'
import History from '@/components/basic/searchEvent/History.vue'
import Popular from '@/components/basic/searchEvent/Popular.vue'
import TopLessonList from '@/components/basic/top/TopLessonList.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormInput,
    History,
    Popular,
    TopLessonList,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      inputData: {},
      sarchwords: '',
      learingType: '',
      choice: [
        '全て',
        'オンライン',
        'オフライン',
      ],
    }
  },
  methods: {
    search() {
      this.$bvToast.toast('検索開始の代理表示です。', {
        title: 'seaching...',
        variant: 'primary',
        solid: true,
      })
    },
    send() {
    },
  },
}
</script>

<style scoped>
  .searchWidth {
    width: 80%;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
