<template>
  <div class="mt-1">
    <div class="titleMain">
      {{ title }}
    </div>
    <b-row>
      <swiper
        class="swiper-navigations swiper-responsive-breakpoints"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(item,index) in lessonList"
          :key="index"
        >
          <b-card
            img-height="200px"
            :img-src="item.lessonPic"
          >
            <a>
              <b-card-text class="">
                <span
                  class="title_"
                  @click="detail(item.docID)"
                >
                  {{ item.lessonName }}
                </span>
                <div class="lessonexplain">
                  {{ item.lessonexplain.slice(0, 30) + '...' }}
                </div>
                <div class="mt-1">
                  <b-badge variant="light-primary">
                    {{ item.maxApplyCount.labelName }}から
                  </b-badge>
                  <b-badge variant="light-success">
                    {{ item.lessonTool.labelName }}
                  </b-badge>
                  <!-- <b-badge variant="light-success">
                    人気
                  </b-badge> -->
                  <b-badge variant="light-secondary">
                    {{ item.studentLevel }}
                  </b-badge>
                </div>
                <div class="mt-1 text-right">
                  <b>{{ item.lessonTime.labelName }} {{ Number(item.coin).toLocaleString() }}<small>コイン</small></b>
                </div>
              </b-card-text>
            </a>
          </b-card>
        </swiper-slide>

        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
      </swiper>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCard, BCardText, BBadge,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import getAllDataList from '@/firestore/lesson/getAllLesson'
import { mapGetters } from 'vuex'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BRow,
    BCard,
    BCardText,
    BBadge,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('lesson', ['lessonList']),
  },
  async mounted() {
    const data = {
      collection: 'lessonRegistration',
    }
    const response = await getAllDataList(data)
    if (response.status === 'success') {
      this.$store.dispatch('lesson/updateList', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    show() {

    },
    detail(id) {
      window.console.log('memo', id)
      // this.$router.push({ path: '/lesson', query: { id } })
    },
    toSlide(index) {
      this.$refs.mySwiper15.$swiper.slideTo(index, 1)
    },
    prependSlides() {
      this.$refs.mySwiper15.$swiper.virtual.prependSlide([
        `Slide ${(this.prependNumber -= 1)}`,
        `Slide ${(this.prependNumber -= 1)}`,
      ])
    },
    appendSlide() {
      this.$refs.mySwiper15.$swiper.virtual.appendSlide(`Slide ${(this.appendNumber += 1)}`)
    },
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
.lessonexplain {
  font-size: 0.8rem;
  margin: 3% 0 0 0;
}
.titleMain {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 3% 0 1% 0;
}
</style>
